export interface UseTimestampOptions {
  /** Return result unit, seconds or milliseconds */
  unit?: 'seconds' | 'milliseconds'
}
/**
 * @description Converts a timestamp string to a specified format (seconds, milliseconds)
 * @export
 * @param {string} timestamp The timestamp string, units can be in seconds or milliseconds
 * @param {UseTimestampOptions} [options={}]
 * @return {*}
 */
export function useTimestamp(
  timestamp: string,
  options: UseTimestampOptions = {},
) {
  const { unit = 'milliseconds' } = options
  if (unit === 'milliseconds') {
    return timestamp.length <= String(Date.now()).length - 3
      ? timestamp + '000'
      : timestamp
  }

  if (unit === 'seconds') {
    return timestamp.length <= String(Date.now()).length - 3
      ? timestamp
      : timestamp.slice(0, -3)
  }
  return ''
}
