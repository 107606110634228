import { Big } from 'big.js'

Big.NE = -18

export interface NumberFormatOptions {
  [key: string]: unknown
  dp?: number
}
/**
 * @description Convert to thousand separator format, the useNumberLimit has been used internally to limit the numeric string format
 * @export
 * @param {(number | string | Big)} num
 * @param {string} [locales='en-US']
 * @param {{ [key: string]: unknown; dp: number }} [option]
 */
export function numberFormat(
  num: number | string | Big,
  locales = 'en-US',
  option?: NumberFormatOptions,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options = option ?? ({} as any)
  const { dp = 8, ...numberFormatOption } = options
  const bigNum = Big(
    useNumberLimit(Big(num || '0').toFixed() as `${number}`, {
      maxDigitsWithZero: dp,
    }).value,
  ).toNumber()
  return new Intl.NumberFormat(locales, {
    ...numberFormatOption,
    maximumFractionDigits: dp,
  }).format(bigNum)
}
/**
 * @note only use with fixed decimals value, eg: strikePrice, do not use with OTCPrice
 * @export
 * @param {(string | number | Big | undefined)} val
 * @return {*}
 */
export function div18(val: string | number | Big | undefined) {
  const newVal = val === '' || val === undefined ? 0 : val
  const x = Big(10).pow(18)
  const y = Big(newVal).div(x).toFixed()
  return y as `${number}`
}
/**
 * @note only use with fixed decimals value, eg: strikePrice, do not use with OTCPrice
 * @export
 * @param {(string | number | Big | undefined)} val
 * @return {*}
 */
export function times18(val: string | number | Big | undefined) {
  const newVal = val === '' || val === undefined ? 0 : val
  const x = Big(10).pow(18)
  const y = Big(newVal).times(x).toFixed()
  return y as `${number}`
}

/**
 * @description Determine if the key and value of the object are equal
 * @param {Object} obj1
 * @param {Object} obj2
 * @return boolean true/false
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectEqual(a: any, b: any): boolean {
  if (a === b) return true

  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()

  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b

  if (a.prototype !== b.prototype) return false

  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false

  return keys.every((k) => objectEqual(a[k], b[k]))
}

/**
 * @deprecated
 */
export function trimAddr(addr: string): string {
  if (!addr) return '...'
  return `${addr.slice(0, 7)}...${addr.slice(-7)}`
}

export interface AbbreviatedAddressParams {
  /**
   * Number of characters to show at the start of the address
   */
  start: number
  /**
   * Number of characters to show at the end of the address
   */
  end: number
  /**
   * Number of dots to show in the middle of the address
   */
  dots: number
}
export function abbreviatedAddress(
  addr: string,
  params = {
    start: 7,
    end: 5,
    dots: 3,
  } as AbbreviatedAddressParams,
): string {
  if (!addr) return ''
  const { start, end, dots } = params
  if (start + end + dots >= addr.length) return addr
  if (end === 0) return `${addr.slice(0, start)}${'.'.repeat(dots)}`
  return `${addr.slice(0, start)}${'.'.repeat(dots)}${addr.slice(-end)}`
}

/**
 * Convert a string to a title case
 * @param { string } str - The string to convert
 * @returns { string } - The converted string
 */
export const toTitleCase = (str: string) => {
  const sentence = str.toLowerCase().split(' ')
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i].charAt(0).toUpperCase() + sentence[i].slice(1)
  }
  return sentence.join(' ')
}

export * from './errors'
export * from './array'
export * from './component'
