import { Big } from 'big.js'
import dayjs from 'dayjs'
import { div18, numberFormat, times18 } from '@base/utils/index'

export const formatPercentage = (target: string, prefix?: string): string => {
  if (!target?.length) {
    return '0%'
  }
  const originalValue = Big(target)
  // return `${numberFormat(originalValue.toFixed(2))}%`
  if (originalValue.gt(10000)) {
    return '>10,000%'
  } else if (originalValue.lt(0.01)) {
    return '<0.01%'
  } else {
    return `${numberFormat(originalValue.toFixed(2))}%`
  }
}

export const getNumberWithTwoDecimals = (number: number | string): string => {
  try {
    return numberWithCommas(Big(number).round(2, Big.roundHalfUp).toFixed(2))
  } catch (error) {
    console.error(error)
    if (typeof number === 'string') {
      const num = Number(number)
      if (isNaN(num)) {
        return number
      }
      return numberWithCommas(num.toFixed(2))
    }
    return numberWithCommas(number.toFixed(2))
  }
}

export function numberWithCommas(x: string | number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const preventPlusMinus = (event: KeyboardEvent | Event): void => {
  if (!event || !(event as KeyboardEvent)?.key) return
  if (
    (event as KeyboardEvent).key !== '+' &&
    (event as KeyboardEvent).key !== '-' &&
    (event as KeyboardEvent).key !== 'e' &&
    (event as KeyboardEvent).key !== 'E'
  )
    return
  event.preventDefault()
}

export const mulStrikePrice = (strikePrice: string | number | Big): string => {
  return times18(strikePrice)
}

export const divStrikePrice = (strikePrice: string | number | Big): string => {
  return div18(strikePrice)
}

export const formatStrikePrice = (
  strikePrice: string | number | Big,
  useDivStrikePrice = true,
) => {
  if (useDivStrikePrice) {
    return '$' + numberFormat(divStrikePrice(strikePrice))
  }
  return '$' + numberFormat(strikePrice)
}

export const formatUsdValueSymbol = (num: string | number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' }, // for millions, not for "mega"
    { value: 1e9, symbol: 'B' }, // for billions, not "G" for "giga"
    { value: 1e12, symbol: 'T' }, // for trillions, not for "tera"
    // { value: 1e15, symbol: 'P' },
    // { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Big(num).gte(item.value)
    })
  const res = item
    ? Big(num)
        .div(item.value)
        .round(1, Big.roundHalfUp)
        .toFixed(1)
        .replace(rx, '$1') + item.symbol
    : Big(num).round(2, Big.roundHalfUp).toFixed(2)
  return '$' + res + (item && item.value > 1 ? '+' : '')
}

/**
 * Get percentage change between two numbers
 * Formula: (curr - prev) / prev * 100
 * @note: if prev is 0, return 10,000%
 * @note: if curr is 0, return 0%
 * @param prev - previous number
 * @param curr - current number
 * @returns percentage change (string with %)
 */
export const getPercentageChange = (prev: number, curr: number) => {
  if (isNaN(prev) || isNaN(curr)) return '0%'
  if (prev === 0) {
    if (curr === 0) return '0%'
    return '>10,000%'
  }
  const percentage = Big(curr).sub(prev).div(prev).mul(100).round(2).toNumber()
  return percentage > 0 ? `+${percentage}%` : `${percentage}%`
}

/**
 * @description: format expiry date to MMM DD, YYYY
 * @param expiry - expiry date in seconds
 */
export const formatExpiry = (expiry: string, format = 'D MMM YYYY') => {
  if (!expiry || isNaN(Number(expiry))) return expiry
  const timestamp = useTimestamp(expiry, { unit: 'seconds' })
  return dayjs(new Date(Number(timestamp) * 1000)).format(format)
}

/**
 * @description Determine whether the passed parameter is a numeric type
 * @export
 * @param {(string | number)} str
 * @return {*}
 */
export function isNumeric(str: string | number) {
  try {
    new Big(str)
    return true
  } catch (error) {
    return false
  }
}
